import { action, computed, makeAutoObservable, observable } from 'mobx'
import { create, persist } from '../mobx-persist'
import { isEmpty, isUndefined } from 'lodash'

import { MSPlanGenericDisplayDetail } from '../interface/common/MSPlanGenericDisplayDetail'
import { MedicareQuotesType } from '../enums/MedicareQuoteTypeEnum'
import { convertNameToTitleCase } from '../utils/StringUtils'

export interface MSPlanDiscountsAppliedType {
  discountNames: string[]
  discountsPercent: number
}

export interface CustomerDTO {
  id: string
  username: string
  password: string
  type: string
  firstName: string
  lastName: string
  postalCode: string
  county: string
  countyFips: string
  state: string
  city: string
  street: string
  dateOfBirth: string
  gender: string
  email: string
  phone: string
  smsOptIn: boolean
  ratesMailingOptIn: boolean
  status: string
  rating: number
  spouseOptIn: boolean
  spouseFirstName: string
  spouseLastName: string
  spouseGender: string
  spouseDateOfBirth: string
  hasHabitSmoking: boolean
  consumesTobacco: boolean
  spouseConsumesTobacco: boolean
  currentCoverageSelf: string
  currentPlanTypeSelf: string
  currentProviderSelf: string
  filterCoverageSelf: string
  filterPlanTypeSelf: string
  filterProviderSelf: string
  filterCoverageSpouse: string
  filterPlanTypeSpouse: string
  filterProviderSpouse: string
  currentMedicarePartBDateSelf: string | null
  msEffectiveDateFilterSelf: string | null
  currentCoverageSpouse: string
  currentPlanTypeSpouse: string
  currentProviderSpouse: string
  currentMedicarePartBDateSpouse: string | null
  additionalPhone: string | null
  mailingOptIn: boolean
  mailingStreet: string
  mailingCity: string
  mailingCounty: string
  mailingState: string
  mailingPostalCode: string
  inquiryNotes: string
  currentPlan: any
  spouseCurrentPlan: any
  newsLetterOptIn: boolean
  pharmacyPostalCode: string
  infoSteps: string[]
  quotesPulledStatusMS: boolean
  quotesPulledStatusMA: boolean
  quotesPulledStatusMPartD: boolean
  isQuotesPullApiCalled: boolean
  isRidersApplied: boolean
  isProfileUpdating: boolean
  maFilterYear: string
  mPartDFilterYear: string
  selectedPlan: string
  isAutoLoggedIn: boolean
  preferenceCoverage: string
  brandName: string
  searchPlan: string
  scrollPosition: string
  isComparePlansCarouselOpen: boolean
  msExpandedCardDetails: MSPlanGenericDisplayDetail
  calculateDrugCostForAEP: boolean
  aepStatus: boolean
  msPageSize: number
  maPageSize: number
  mpdPageSize: number
  maPageNumber: number
  msPageNumber: number
  mpdPageNumber: number
  isSsnProvided: boolean
  recordId: string
  mMinimumDrugCount: number
  isMEnabled: boolean
  recommendedPlanType: MedicareQuotesType
}

interface SpouseBasicDetails {
  spouseOptIn: boolean
  spouseFirstName: string
  spouseLastName: string
  spouseGender: string
  spouseDateOfBirth: string
}

interface EnrollmentRequestPlanType {
  planId: string
  planType: MedicareQuotesType | null
}

interface SavePlanStatusObjectType {
  planId: string
  planType: MedicareQuotesType | null
  status: boolean
}

const hydrate = create({
  storage: sessionStorage,
  jsonify: true,
})

class CustomerStore {
  @persist @observable id = ''
  @persist @observable username = ''
  @persist @observable type = ''
  @persist @observable firstName = ''
  @persist @observable lastName = ''
  @persist @observable postalCode = ''
  @persist @observable county = ''
  @persist @observable countyFips = ''
  @persist @observable state = ''
  @persist @observable city = ''
  @persist @observable street = ''
  @persist @observable dateOfBirth = ''
  @persist @observable gender = ''
  @persist @observable email = ''
  @persist @observable phone = ''
  @persist @observable smsOptIn = false
  @persist @observable newsLetterOptIn = false
  @persist @observable ratesMailingOptIn = false
  @persist @observable status = ''
  @persist @observable rating = 0
  // spouseOptIn means, spouse is also part of plan.
  @persist @observable spouseOptIn = true
  // forSpouse is a check to know, if we are working with spouse or the customer.
  @observable forSpouse = false
  @persist @observable spouseFirstName = ''
  @persist @observable spouseLastName = ''
  @persist @observable spouseGender = ''
  @persist @observable spouseDateOfBirth = ''
  @persist @observable hasHabitSmoking = false
  @persist @observable consumesTobacco = false
  @persist @observable spouseConsumesTobacco = false
  @persist @observable currentCoverageSelf = ''
  @persist @observable currentPlanTypeSelf = ''
  @persist @observable currentProviderSelf = ''
  @persist @observable currentMedicarePartBDateSelf: string | null = null
  @persist @observable currentCoverageSpouse = ''
  @persist @observable currentPlanTypeSpouse = ''
  @persist @observable currentProviderSpouse = ''
  @persist @observable currentMedicarePartBDateSpouse: string | null = null
  @persist @observable additionalPhone: string | null = null
  @persist @observable mailingOptIn = false
  @persist @observable mailingStreet = ''
  @persist @observable mailingCity = ''
  @persist @observable mailingCounty = ''
  @persist @observable mailingState = ''
  @persist @observable mailingPostalCode = ''
  @persist @observable inquiryNotes = ''
  @persist @observable currentTab = -1
  @persist @observable preferenceCoverage = ''
  @persist('list') @observable infoSteps: string[] = []

  @persist('object') @observable currentPlanCustomer: any = null
  @persist('object') @observable currentPlanSpouse: any = null
  @persist('object')
  @observable
  enrollmentRequestPlan: EnrollmentRequestPlanType = {
    planId: '',
    planType: null,
  }
  @persist('object')
  @observable
  savePlanStatusObject: SavePlanStatusObjectType = {
    planId: '',
    planType: null,
    status: false,
  }
  @persist @observable pharmacyPostalCode = ''
  @persist('map')
  @observable
  msPlanDiscountsApplied: Map<string, MSPlanDiscountsAppliedType> = new Map<
    string,
    MSPlanDiscountsAppliedType
  >()
  @persist @observable brandName: string = ''

  @observable isMSQuotesProcessing: boolean = false
  @observable isMAQuotesProcessing: boolean = false
  @observable isMPartDQuotesProcessing: boolean = false
  @observable isQuotesPullApiCalled: boolean = false
  @observable isProfileUpdating: boolean = false
  @observable maFilterYear: string = ''
  @observable mPartDFilterYear: string = ''
  @observable msPageSize: number = 0
  @observable maPageSize: number = 0
  @observable mpdPageSize: number = 0
  @observable maPageNumber: number = 1
  @observable msPageNumber: number = 1
  @observable mpdPageNumber: number = 1
  @observable searchPlan: string = ''
  @observable scrollPosition = ''
  @observable isComparePlansCarouselOpen = false
  @observable filterCoverageSelf = ''
  @observable filterProviderSelf = ''
  @observable filterPlanTypeSelf = ''
  @observable filterCoverageSpouse = ''
  @observable filterProviderSpouse = ''
  @observable filterPlanTypeSpouse = ''
  @observable msExpandedCardDetails = {} as MSPlanGenericDisplayDetail
  @persist @observable calculateDrugCostForAEP = false
  @persist @observable aepStatus: boolean | undefined = undefined

  @persist @observable isRidersApplied: boolean = false
  @persist @observable selectedPlan = ''
  @persist @observable isAutoLoggedIn: boolean = false
  @persist @observable brandLogoUrl: string = ''
  @persist @observable brandLogoAltUrl: string = ''
  @persist @observable msEffectiveDateFilterSelf: string | null = null
  @persist @observable showResetPasswordPrompt = true
  @persist @observable isSsnProvided: boolean = false
  @persist @observable recordId: string = ''
  @persist @observable mMinimumDrugCount: number = 0
  @persist @observable isMEnabled: boolean = false
  @observable isMillimanRequestProcessing: boolean = false
  @persist @observable recommendedPlanType:
    | MedicareQuotesType
    | undefined
    | null

  @action set(customerObj: Partial<CustomerDTO>) {
    if (customerObj.id) this.id = customerObj.id
    if (customerObj.username) this.username = customerObj.username
    if (customerObj.type) this.type = customerObj.type
    if (customerObj.firstName) this.firstName = customerObj.firstName
    if (customerObj.lastName) this.lastName = customerObj.lastName
    if (customerObj.postalCode) this.postalCode = customerObj.postalCode
    if (customerObj.county) this.county = customerObj.county
    if (customerObj.countyFips) this.countyFips = customerObj.countyFips
    if (customerObj.state) this.state = customerObj.state
    if (customerObj.city) this.city = customerObj.city
    if (customerObj.street) this.street = customerObj.street
    if (customerObj.dateOfBirth) this.dateOfBirth = customerObj.dateOfBirth
    if (customerObj.gender) this.gender = customerObj.gender
    if (customerObj.email) this.email = customerObj.email
    if (customerObj.phone) this.phone = customerObj.phone
    if (customerObj.smsOptIn) this.smsOptIn = customerObj.smsOptIn
    if (customerObj.status) this.status = customerObj.status
    if (customerObj.rating) this.rating = customerObj.rating
    if (customerObj.recommendedPlanType)
      this.recommendedPlanType = customerObj.recommendedPlanType
    if (!isUndefined(customerObj.spouseOptIn))
      this.spouseOptIn = customerObj.spouseOptIn
    if (customerObj.spouseFirstName)
      this.spouseFirstName = customerObj.spouseFirstName
    if (customerObj.spouseLastName)
      this.spouseLastName = customerObj.spouseLastName
    if (isUndefined(this.spouseOptIn)) {
      this.spouseGender = ''
      this.spouseDateOfBirth = ''
    } else {
      if (customerObj.spouseGender) this.spouseGender = customerObj.spouseGender
      if (customerObj.spouseDateOfBirth)
        this.spouseDateOfBirth = customerObj.spouseDateOfBirth
    }
    if (!isUndefined(customerObj.hasHabitSmoking))
      this.hasHabitSmoking = customerObj.hasHabitSmoking
    if (!isUndefined(customerObj.consumesTobacco))
      this.consumesTobacco = customerObj.consumesTobacco
    if (!isUndefined(customerObj.spouseConsumesTobacco))
      this.spouseConsumesTobacco = customerObj.spouseConsumesTobacco
    if (!isUndefined(customerObj.currentCoverageSelf))
      this.currentCoverageSelf = !isEmpty(customerObj.currentCoverageSelf)
        ? customerObj.currentCoverageSelf
        : ''
    if (!isUndefined(customerObj.currentPlanTypeSelf))
      this.currentPlanTypeSelf = !isEmpty(customerObj.currentPlanTypeSelf)
        ? customerObj.currentPlanTypeSelf
        : ''
    if (!isUndefined(customerObj.currentProviderSelf))
      this.currentProviderSelf = !isEmpty(customerObj.currentProviderSelf)
        ? customerObj.currentProviderSelf
        : ''
    if (customerObj.currentMedicarePartBDateSelf)
      this.currentMedicarePartBDateSelf =
        customerObj.currentMedicarePartBDateSelf
    else this.currentMedicarePartBDateSelf = ''
    if (!isUndefined(customerObj.currentCoverageSpouse))
      this.currentCoverageSpouse = !isEmpty(customerObj.currentCoverageSpouse)
        ? customerObj.currentCoverageSpouse
        : ''
    if (!isUndefined(customerObj.currentPlanTypeSpouse))
      this.currentPlanTypeSpouse = !isEmpty(customerObj.currentPlanTypeSpouse)
        ? customerObj.currentPlanTypeSpouse
        : ''
    if (!isUndefined(customerObj.currentProviderSpouse))
      this.currentProviderSpouse = !isEmpty(customerObj.currentProviderSpouse)
        ? customerObj.currentProviderSpouse
        : ''
    if (!isUndefined(customerObj.currentMedicarePartBDateSpouse))
      this.currentMedicarePartBDateSpouse =
        customerObj.currentMedicarePartBDateSpouse
    else this.currentMedicarePartBDateSpouse = ''
    if (!isUndefined(customerObj.additionalPhone))
      this.additionalPhone = customerObj.additionalPhone
    if (customerObj.mailingOptIn) this.mailingOptIn = customerObj.mailingOptIn
    if (customerObj.newsLetterOptIn)
      this.newsLetterOptIn = customerObj.newsLetterOptIn
    if (customerObj.ratesMailingOptIn)
      this.ratesMailingOptIn = customerObj.ratesMailingOptIn
    if (customerObj.mailingStreet)
      this.mailingStreet = customerObj.mailingStreet
    if (customerObj.mailingCity) this.mailingCity = customerObj.mailingCity
    if (customerObj.mailingCounty)
      this.mailingCounty = customerObj.mailingCounty
    if (customerObj.mailingState) this.mailingState = customerObj.mailingState
    if (customerObj.mailingPostalCode)
      this.mailingPostalCode = customerObj.mailingPostalCode
    if (customerObj.inquiryNotes) this.inquiryNotes = customerObj.inquiryNotes
    if (customerObj.pharmacyPostalCode)
      this.pharmacyPostalCode = customerObj.pharmacyPostalCode
    this.currentPlanCustomer = customerObj.currentPlan || null
    this.currentPlanSpouse = customerObj.spouseCurrentPlan || null
    this.isRidersApplied = customerObj.isRidersApplied || false
    if (customerObj.selectedPlan) this.selectedPlan = customerObj.selectedPlan
    if (customerObj.isAutoLoggedIn)
      this.isAutoLoggedIn = customerObj.isAutoLoggedIn
    if (customerObj.preferenceCoverage) {
      this.preferenceCoverage = customerObj.preferenceCoverage
      switch (customerObj.preferenceCoverage) {
        case 'ma':
          this.currentTab = 0
          break
        case 'ms':
          this.currentTab = 1
          break
        case 'pdp':
          this.currentTab = 2
          break
        default:
          this.currentTab = 0
      }
    }
    if (customerObj.brandName) this.brandName = customerObj.brandName
    if (customerObj.msEffectiveDateFilterSelf)
      this.msEffectiveDateFilterSelf = customerObj.msEffectiveDateFilterSelf
    else this.msEffectiveDateFilterSelf = ''

    this.aepStatus = customerObj.aepStatus === true ? true : false
    this.calculateDrugCostForAEP =
      customerObj.calculateDrugCostForAEP === true ? true : false
    this.isSsnProvided = customerObj.isSsnProvided === true ? true : false
    if (customerObj.mMinimumDrugCount) {
      this.mMinimumDrugCount = customerObj.mMinimumDrugCount
    }
    this.isMEnabled = customerObj.isMEnabled === true ? true : false
    if (customerObj.recordId) {
      this.recordId = customerObj.recordId
    }
  }

  @action setAEPStatus(status: boolean) {
    this.aepStatus = status
  }

  @action setIsSsnProvided(isSsnProvided: boolean) {
    this.isSsnProvided = isSsnProvided
  }

  @action setRecordId(recordId: string) {
    this.recordId = recordId
  }

  @action setMMinimumDrugCount(mMinimumDrugCount: number) {
    this.mMinimumDrugCount = mMinimumDrugCount
  }

  @action setisMEnabled(isMEnabled: boolean) {
    this.isMEnabled = isMEnabled
  }

  @action setCalculateDrugCostForAEP(status: boolean) {
    this.calculateDrugCostForAEP = status
  }

  get() {
    return {
      id: this.id,
      username: this.username,
      type: this.type,
      firstName: this.firstName,
      lastName: this.lastName,
      postalCode: this.postalCode,
      county: this.county,
      countyFips: this.countyFips,
      state: this.state,
      city: this.city,
      street: this.street,
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      email: this.email,
      phone: this.phone,
      smsOptIn: this.smsOptIn,
      ratesMailingOptIn: this.ratesMailingOptIn,
      newsLetterOptIn: this.newsLetterOptIn,
      status: this.status,
      rating: this.rating,
      spouseOptIn: this.spouseOptIn,
      forSpouse: this.forSpouse,
      spouseFirstName: this.spouseFirstName,
      spouseLastName: this.spouseLastName,
      spouseGender: this.spouseGender,
      spouseDateOfBirth: this.spouseDateOfBirth,
      hasHabitSmoking: this.hasHabitSmoking,
      consumesTobacco: this.consumesTobacco,
      spouseConsumesTobacco: this.consumesTobacco,
      currentCoverageSelf: this.currentCoverageSelf,
      currentPlanTypeSelf: this.currentPlanTypeSelf,
      currentProviderSelf: this.currentProviderSelf,
      filterCoverageSelf: this.filterCoverageSelf,
      filterPlanTypeSelf: this.filterPlanTypeSelf,
      filterProviderSelf: this.filterProviderSelf,
      filterCoverageSpouse: this.filterCoverageSpouse,
      filterPlanTypeSpouse: this.filterPlanTypeSpouse,
      filterProviderSpouse: this.filterProviderSpouse,

      currentMedicarePartBDateSelf: this.currentMedicarePartBDateSelf,
      currentCoverageSpouse: this.currentCoverageSpouse,
      currentPlanTypeSpouse: this.currentPlanTypeSpouse,
      currentProviderSpouse: this.currentProviderSpouse,
      currentMedicarePartBDateSpouse: this.currentMedicarePartBDateSpouse,
      currentTab: this.currentTab,
      additionalPhone: this.additionalPhone,
      mailingOptIn: this.mailingOptIn,
      mailingStreet: this.mailingStreet,
      mailingCity: this.mailingCity,
      mailingCounty: this.mailingCounty,
      mailingState: this.mailingState,
      mailingPostalCode: this.mailingPostalCode,
      inquiryNotes: this.inquiryNotes,
      currentPlanCustomer: this.currentPlanCustomer,
      currentPlanSpouse: this.currentPlanSpouse,
      pharmacyPostalCode: this.pharmacyPostalCode,
      infoSteps: this.infoSteps,
      msPlanDiscountsApplied: this.msPlanDiscountsApplied,
      quotesPulledStatusMS: this.isMSQuotesProcessing,
      quotesPulledStatusMA: this.isMAQuotesProcessing,
      quotesPulledStatusMPartD: this.isMPartDQuotesProcessing,
      isQuotesPullApiCalled: this.isQuotesPullApiCalled,
      isRidersApplied: this.isRidersApplied,
      isAutoLoggedIn: this.isAutoLoggedIn,
      isProfileUpdating: this.isProfileUpdating,
      maFilterYear: this.maFilterYear,
      mPartDFilterYear: this.mPartDFilterYear,
      searchPlan: this.searchPlan,
      scrollposition: this.scrollPosition,
      isComparePlansCarouselOpen: this.isComparePlansCarouselOpen,
      msExpandedCardDetails: this.msExpandedCardDetails,
      preferenceCoverage: this.preferenceCoverage,
      brandName: this.brandName,
      brandLogoUrl: this.brandLogoUrl,
      brandLogoAltUrl: this.brandLogoAltUrl,
      msEffectiveDateFilterSelf: this.msEffectiveDateFilterSelf,
      aepStatus: this.aepStatus,
      isSsnProvided: this.isSsnProvided,
      recordId: this.recordId,
      mMinimumDrugCount: this.mMinimumDrugCount,
      isMEnabled: this.isMEnabled,
      calculateDrugCostForAEP: this.calculateDrugCostForAEP,
      msPageSize: this.msPageSize,
      maPageSize: this.maPageSize,
      mpdPageSize: this.mpdPageSize,
      maPageNumber: this.maPageNumber,
      msPageNumber: this.msPageNumber,
      mpdPageNumber: this.mpdPageNumber,
      isMillimanRequestProcessing: this.isMillimanRequestProcessing,
    }
  }

  @action reset = () => {
    this.id = ''
    this.username = ''
    this.type = ''
    this.firstName = ''
    this.lastName = ''
    this.postalCode = ''
    this.county = ''
    this.countyFips = ''
    this.state = ''
    this.city = ''
    this.street = ''
    this.dateOfBirth = ''
    this.gender = ''
    this.email = ''
    this.phone = ''
    this.smsOptIn = false
    this.newsLetterOptIn = false
    this.ratesMailingOptIn = false
    this.status = ''
    this.rating = 0
    this.currentTab = 0
    this.spouseOptIn = false
    this.forSpouse = false
    this.spouseFirstName = ''
    this.spouseLastName = ''
    this.spouseGender = ''
    this.spouseDateOfBirth = ''
    this.hasHabitSmoking = false
    this.consumesTobacco = false
    this.spouseConsumesTobacco = false
    this.currentCoverageSelf = ''
    this.currentPlanTypeSelf = ''
    this.currentProviderSelf = ''
    this.filterCoverageSelf = ''
    this.filterPlanTypeSelf = ''
    this.filterProviderSelf = ''
    this.filterCoverageSpouse = ''
    this.filterPlanTypeSpouse = ''
    this.filterProviderSpouse = ''
    this.currentMedicarePartBDateSelf = null
    this.currentCoverageSpouse = ''
    this.currentPlanTypeSpouse = ''
    this.currentProviderSpouse = ''
    this.currentMedicarePartBDateSpouse = null
    this.additionalPhone = null
    this.mailingOptIn = false
    this.mailingStreet = ''
    this.mailingCity = ''
    this.mailingCounty = ''
    this.mailingState = ''
    this.mailingPostalCode = ''
    this.inquiryNotes = ''
    this.currentTab = 0
    this.infoSteps = []
    this.currentPlanCustomer = null
    this.currentPlanSpouse = null
    this.enrollmentRequestPlan = {
      planId: '',
      planType: null,
    }
    this.savePlanStatusObject = {
      planId: '',
      planType: null,
      status: false,
    }
    this.pharmacyPostalCode = ''
    this.msPlanDiscountsApplied.clear()
    this.isMAQuotesProcessing = false
    this.isMPartDQuotesProcessing = false
    this.isMSQuotesProcessing = false
    this.isQuotesPullApiCalled = false
    this.isProfileUpdating = false
    this.maFilterYear = ''
    this.mPartDFilterYear = ''
    this.searchPlan = ''
    this.scrollPosition = ''
    this.isComparePlansCarouselOpen = false
    this.msExpandedCardDetails = {} as MSPlanGenericDisplayDetail
    this.isRidersApplied = false
    this.isAutoLoggedIn = false
    this.preferenceCoverage = ''
    this.brandName = ''
    this.brandLogoUrl = ''
    this.brandLogoAltUrl = ''
    this.msEffectiveDateFilterSelf = null
    this.showResetPasswordPrompt = true
    this.aepStatus = false
    this.calculateDrugCostForAEP = false
    this.msPageSize = 0
    this.maPageSize = 0
    this.mpdPageSize = 0
    this.maPageNumber = 1
    this.msPageNumber = 1
    this.mpdPageNumber = 1
    this.isSsnProvided = false
    this.isMEnabled = false
    this.mMinimumDrugCount = 0
    this.isMillimanRequestProcessing = false
    this.recordId = ''
  }

  getBrandLogoUrl() {
    return this.brandLogoUrl
  }

  getAepStatus() {
    return this.aepStatus
  }

  getMMinimumDrugCount() {
    return this.mMinimumDrugCount
  }

  getIsMEnabled() {
    return this.isMEnabled
  }

  getIsSsnProvided() {
    return this.isSsnProvided
  }

  getRecordId() {
    return this.recordId
  }

  getCalculateDrugCostForAEP() {
    return this.calculateDrugCostForAEP
  }

  @action setBrandLogoUrl(logoUrl: string) {
    this.brandLogoUrl = logoUrl
  }

  getBrandLogoAltUrl() {
    return this.brandLogoAltUrl
  }

  @action setBrandLogoAltUrl(logoUrl: string) {
    this.brandLogoAltUrl = logoUrl
  }

  @action setQuotePulledStatusMS(quotesPulledStatusMS: boolean) {
    if (!isUndefined(quotesPulledStatusMS)) {
      this.isMSQuotesProcessing = quotesPulledStatusMS
    }
  }

  @action setQuotePulledStatusMA(quotesPulledStatusMA: boolean) {
    if (!isUndefined(quotesPulledStatusMA)) {
      this.isMAQuotesProcessing = quotesPulledStatusMA
    }
  }

  @action setIsQuotesPullAPiCalled(isQuotesPullApiCalled: boolean) {
    if (!isUndefined(isQuotesPullApiCalled)) {
      this.isQuotesPullApiCalled = isQuotesPullApiCalled
    }
  }

  @action setIsProfileUpdating(isProfileUpdating: boolean) {
    if (!isUndefined(isProfileUpdating)) {
      this.isProfileUpdating = isProfileUpdating
    }
  }

  @action setMAFilterYear(maFilterYear: string) {
    if (!isUndefined(maFilterYear)) {
      this.maFilterYear = maFilterYear
    }
  }

  @action setMPartDFilterYear(mPartDFilterYear: string) {
    if (!isUndefined(mPartDFilterYear)) {
      this.mPartDFilterYear = mPartDFilterYear
    }
  }

  @action setSearchPlan(searchPlan: string) {
    if (!isUndefined(searchPlan)) {
      this.searchPlan = searchPlan
    }
  }

  @action setScrollPosition(scrollPosition: string) {
    if (!isUndefined(scrollPosition)) {
      this.scrollPosition = scrollPosition
    }
  }

  @action setIsComparePlansCarouselOpen(isComparePlansCarouselOpen: boolean) {
    if (!isUndefined(isComparePlansCarouselOpen)) {
      this.isComparePlansCarouselOpen = isComparePlansCarouselOpen
    }
  }

  @action setMSExpandedCardDetails(
    msExpandedCardDetails: MSPlanGenericDisplayDetail
  ) {
    if (!isUndefined(msExpandedCardDetails)) {
      this.msExpandedCardDetails = msExpandedCardDetails
    }
  }

  @action setMSPageSize(msPageSize: number) {
    if (!isUndefined(msPageSize)) {
      this.msPageSize = msPageSize
    }
  }

  @action setMAPageSize(maPageSize: number) {
    if (!isUndefined(maPageSize)) {
      this.maPageSize = maPageSize
    }
  }

  @action setMPDPageSize(mpdPageSize: number) {
    if (!isUndefined(mpdPageSize)) {
      this.mpdPageSize = mpdPageSize
    }
  }

  @action setMAPageNumber(maPageNumber: number) {
    if (!isUndefined(maPageNumber)) {
      this.maPageNumber = maPageNumber
    }
  }

  @action setMSPageNumber(msPageNumber: number) {
    if (!isUndefined(msPageNumber)) {
      this.msPageNumber = msPageNumber
    }
  }

  @action setMPDPageNumber(mpdPageNumber: number) {
    if (!isUndefined(mpdPageNumber)) {
      this.mpdPageNumber = mpdPageNumber
    }
  }

  @action setQuotePulledStatusMPartD(quotesPulledStatusMPartD: boolean) {
    if (!isUndefined(quotesPulledStatusMPartD)) {
      this.isMPartDQuotesProcessing = quotesPulledStatusMPartD
    }
  }

  @action setMillimanRequestStatus(millimanRequestStatus: boolean) {
    if (!isUndefined(millimanRequestStatus)) {
      this.isMillimanRequestProcessing = millimanRequestStatus
    }
  }

  @action setIsRidersApplied(isRidersApplied: boolean) {
    if (!isUndefined(isRidersApplied)) {
      this.isRidersApplied = isRidersApplied
    }
  }

  @action setPostalCode(postalCode: string) {
    this.postalCode = postalCode
  }

  @action setCurrentTab(currentTab: number) {
    this.currentTab = currentTab
  }

  @action setEnrollmentRequestedPlan(
    enrollmentRequestedPlan: EnrollmentRequestPlanType
  ) {
    this.enrollmentRequestPlan = enrollmentRequestedPlan
  }

  @action setSavePlanStatusObject(
    savePlanStatusObject: SavePlanStatusObjectType
  ) {
    this.savePlanStatusObject = savePlanStatusObject
  }

  @action setPharmacyPostalCode(pharmacyPostalCode: string) {
    this.pharmacyPostalCode = pharmacyPostalCode
  }

  @action setForSpouse(forSpouse: boolean) {
    this.forSpouse = forSpouse
  }

  @action setStatus(status: string) {
    if (!isEmpty(status)) {
      this.status = status
    }
  }

  @action setSpouseBasicDetails(spouseBasicDetails: SpouseBasicDetails) {
    this.spouseDateOfBirth = spouseBasicDetails.spouseDateOfBirth
    this.spouseGender = spouseBasicDetails.spouseGender
    this.spouseFirstName = spouseBasicDetails.spouseFirstName
    this.spouseLastName = spouseBasicDetails.spouseLastName
    this.spouseOptIn = spouseBasicDetails.spouseOptIn
  }

  @action setInfoSteps(newInfoSteps: string | string[]) {
    if (typeof newInfoSteps === 'string') {
      if (this.infoSteps.includes(newInfoSteps)) return
      else {
        const tempInfoSteps = this.infoSteps
        tempInfoSteps.push(newInfoSteps)
        this.infoSteps = tempInfoSteps
      }
    } else {
      if (newInfoSteps && newInfoSteps.length !== 0)
        this.infoSteps = [...(newInfoSteps as string[])]
      else this.infoSteps = []
    }
  }

  @action setConsumesTobacco(doesConsumeTobacco: boolean) {
    this.consumesTobacco = doesConsumeTobacco
  }

  @action setPreferenceCoverage(preferenceCoverage: string) {
    this.preferenceCoverage = preferenceCoverage
  }

  @action setMSPlanDiscountsApplied(
    planId: string,
    msDiscountsApplied: MSPlanDiscountsAppliedType
  ) {
    if (isEmpty(msDiscountsApplied)) this.msPlanDiscountsApplied.delete(planId)
    else this.msPlanDiscountsApplied.set(planId, msDiscountsApplied)
  }

  @action setCurrentMedicarePartBDateSelf(
    currentMedicarePartBDateSelf: string | null
  ) {
    this.currentMedicarePartBDateSelf = currentMedicarePartBDateSelf
  }

  @action setSupplementalQuestionAnswers(data: {
    currentCoverageSelf: string
    currentPlanTypeSelf: string
    currentProviderSelf: string
    currentMedicarePartBDateSelf: string | null
  }) {
    this.currentCoverageSelf = data.currentCoverageSelf
    this.currentPlanTypeSelf = data.currentPlanTypeSelf
    this.currentProviderSelf = data.currentProviderSelf
    this.currentMedicarePartBDateSelf = data.currentMedicarePartBDateSelf
  }

  @action setFiltersInfoStep(data: {
    coverageSelf: string
    planTypeSelf: string
    providerSelf: string
  }) {
    this.filterCoverageSelf = data.coverageSelf
    this.filterPlanTypeSelf = data.planTypeSelf
    this.filterProviderSelf = data.providerSelf
  }

  @action setSpouseSupplementalQuestionAnswers(data: {
    currentCoverageSpouse: string
    currentPlanTypeSpouse: string
    currentProviderSpouse: string
    currentMedicarePartBDateSpouse: string | null
  }) {
    this.currentCoverageSpouse = data.currentCoverageSpouse
    this.currentPlanTypeSpouse = data.currentPlanTypeSpouse
    this.currentProviderSpouse = data.currentProviderSpouse
    this.currentMedicarePartBDateSpouse = data.currentMedicarePartBDateSpouse
  }

  @action setSpouseFiltersInfoStep(data: {
    coverageSpouse: string
    planTypeSpouse: string
    providerSpouse: string
  }) {
    this.filterCoverageSpouse = data.coverageSpouse
    this.filterPlanTypeSpouse = data.planTypeSpouse
    this.filterProviderSpouse = data.providerSpouse
  }

  @action setShowResetPasswordPrompt(showResetPasswordPrompt: boolean) {
    this.showResetPasswordPrompt = showResetPasswordPrompt
  }

  @action setMSEffectiveDateFilterSelf(
    msEffectiveDateFilterSelf: string | null
  ) {
    this.msEffectiveDateFilterSelf = msEffectiveDateFilterSelf
  }

  @action setSpouseOptIn(optIn: boolean) {
    this.spouseOptIn = optIn
  }

  getMSPlanDiscountsApplied(planId: string) {
    if (this.msPlanDiscountsApplied.size > 0) {
      return this.msPlanDiscountsApplied.get(planId)
    } else {
      return undefined
    }
  }

  isPharmacyAdded() {
    return customerStore.infoSteps.includes('pharmacy')
  }

  isPrescriptionAdded() {
    return customerStore.infoSteps.includes('prescription')
  }

  @computed
  getCurrentTabStatus(currentTab: number): boolean {
    switch (currentTab) {
      case 0:
        return this.isMAQuotesProcessing
      case 1:
        return this.isMSQuotesProcessing
      case 2:
        return this.isMPartDQuotesProcessing
      default:
        return false
    }
  }
  @computed
  getUsername() {
    return this.username
  }
  @computed
  getFullName() {
    return (
      convertNameToTitleCase(this.firstName) +
      ' ' +
      convertNameToTitleCase(this.lastName)
    ).trim()
  }

  @computed
  getSpouseFullName() {
    let spouseName = (
      convertNameToTitleCase(this.spouseFirstName) +
      ' ' +
      convertNameToTitleCase(this.spouseLastName)
    ).trim()
    return isEmpty(spouseName) ? '-' : spouseName
  }

  @computed
  getPostalCodeAndCounty() {
    return this.postalCode + ', ' + this.county
  }

  @computed
  getEnrollmentRequestPlan() {
    return this.enrollmentRequestPlan
  }

  @computed
  getSavePlanStatusObject() {
    return this.savePlanStatusObject
  }

  @computed
  isUserPartOfRole(roleName: string) {
    return this.type === roleName
  }

  @computed
  getDOB() {
    return this.dateOfBirth
  }

  @computed
  getSpouseDOB() {
    return this.spouseDateOfBirth
  }

  @computed
  getGender() {
    return this.gender
  }

  @computed
  getSpouseGender() {
    return this.spouseGender
  }

  @computed
  getConsumesTobacco() {
    return this.consumesTobacco
  }

  @computed
  getSpouseConsumesTobacco() {
    return this.spouseConsumesTobacco
  }

  @computed
  getSpouseOptIn() {
    return this.spouseOptIn
  }

  @computed
  getBrandName() {
    return this.brandName
  }

  @computed
  getShowResetPasswordPrompt() {
    return this.showResetPasswordPrompt
  }

  constructor() {
    makeAutoObservable(this)
    Promise.all([hydrate('customerStore', this)])
  }
}

const customerStore = new CustomerStore()

export default customerStore
